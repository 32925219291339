import { fetchProfile } from "@/api/index";

const actions = {
  /**
   * LOGIN
   */
  LOGIN({ commit }, data) {
    commit("LOGIN", data.accesstoken);
    // commit("SET_USER_INFO", data.userInfo);
  },
  /*
   * LOGOUT
   */
  LOGOUT({ commit }) {
    commit("LOGOUT");
    window.location.href = "/";
  },

  /**
   * fetch User 정보
   */
  getAccountInfo({ commit, dispatch }) {
    fetchProfile().then(async (res) => {
      if (res.data.status == 200) {
        let data = {
          adminType: res.data.data.adminType,
          email: res.data.data.email,
          _id: res.data.data._id,
          isAdmin: res.data.data.isAdmin,
          mobileNo: res.data.data.mobileNo,
          username: res.data.data.username,
          userType: res.data.data.userType,
          status: res.data.data.status,
        };

        commit("SET_USER_INFO", data);
      } else {
        dispatch("LOGOUT");
      }
    });
  },

  // async getNewRequestList({ commit }) {
  //   let params = { status: "request" };
  //   fetchEstimateList(params).then((res) => {
  //     let count = res.data.total;

  //     commit("SET_ESTIMATE_COUNT", count);
  //   });
  //   fetchScheduleList(params).then((res) => {
  //     let count = res.data.total;
  //     commit("SET_SCHEDULE_COUNT", count);
  //   });
  // },

  SET_NAVBAR({ commit }, data) {
    commit("SET_NAVBAR", data);
  },
  SET_NAVBAR_VISIBLE({ commit }, data) {
    commit("SET_NAVBAR_VISIBLE", data);
  },
  SET_FOOTER({ commit }, data) {
    commit("SET_FOOTER", data);
  },
  SET_USER_INFO({ commit }, data) {
    commit("SET_USER_INFO", data);
  },
};

export default actions;
