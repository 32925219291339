import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// const requireAuth = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken == null || accesstoken == undefined) {
//     alert("로그인 후 사용가능 합니다.");
//     next("/");
//   } else {
//     next();
//   }
//   next();
// };

// NavigationDuplicated Error 해결 코드
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

const requireAdmin = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  if (accesstoken) {
    next();
  } else {
    next("/");
  }
};

// // PLANNER & ADVISOR & SUPER
// const requirePAS = () => (to, from, next) => {
//   let adminType = localStorage.getItem("adminType");
//   if (adminType == "ADVISOR" || adminType == "SUPER") {
//     next();
//   } else {
//     next("/");
//   }
// };
// // MANAGER & ADVISOR & SUPER
// const requireMAS = () => (to, from, next) => {
//   let adminType = localStorage.getItem("adminType");
//   if (
//     adminType == "MANAGER" ||
//     adminType == "ADVISOR" ||
//     adminType == "SUPER"
//   ) {
//     next();
//   } else {
//     next("/");
//   }
// };

// //  SUPER
// const requireSuper = () => (to, from, next) => {
//   let adminType = localStorage.getItem("adminType");
//   if (adminType == "SUPER") {
//     next();
//   } else {
//     next("/");
//   }
// };

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/account/Login.vue"),
  },

  {
    path: "/account",
    name: "account",
    component: () => import("../views/account/Container.vue"),

    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/account/Login.vue"),
      },
    ],
  },

  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/Container.vue"),

    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/admin/dashboard/Index.vue"),
      },
      {
        path: "users",
        name: "users",
        component: () => import("../views/admin/users/Manage.vue"),
        beforeEnter: requireAdmin(),
      },

      {
        path: "users/detail",
        name: "userDetail",
        component: () => import("../views/admin/users/Detail.vue"),
      },
      {
        path: "application/manage",
        name: "applyemanage",
        component: () => import("../views/admin/application/Manage.vue"),
      },
      {
        path: "application/register",
        name: "applyRegister",
        component: () => import("../views/admin/application/Register.vue"),
      },
      {
        path: "application/list",
        name: "applications",
        component: () => import("../views/admin/application/List.vue"),
      },
      {
        path: "application/detail",
        name: "applicationDetail",
        component: () => import("../views/admin/application/Detail.vue"),
      },
      {
        path: "lecture/manage",
        name: "lectureemanage",
        component: () => import("../views/admin/lecture/Manage.vue"),
      },
      {
        path: "lecture/register",
        name: "lectureRegister",
        component: () => import("../views/admin/lecture/Register.vue"),
      },
      {
        path: "review/manage",
        name: "reviewmanage",
        component: () => import("../views/admin/review/Manage.vue"),
      },
      {
        path: "review/detail",
        name: "reviewdetail",
        component: () => import("../views/admin/review/Detail.vue"),
      },
      {
        path: "provider/manage",
        name: "providerManage",
        component: () => import("../views/admin/provider/Manage.vue"),
      },
      {
        path: "provider/register",
        name: "providerRegister",
        component: () => import("../views/admin/provider/Register.vue"),
      },
      {
        path: "schedule/manage",
        name: "schedulemanage",
        component: () => import("../views/admin/schedule/Manage.vue"),
      },
      {
        path: "schedule/register",
        name: "scheduleregister",
        component: () => import("../views/admin/schedule/Register.vue"),
      },
      {
        path: "banner/manage",
        name: "bannermanage",
        component: () => import("../views/admin/banner/Banner.vue"),
      },

      {
        path: "/error404",
        name: "error404",
        component: () => import("../views/admin/pop/Error404.vue"),
      },
      {
        path: "/error503",
        name: "error503",
        component: () => import("../views/admin/pop/Error503.vue"),
      },
      {
        path: "order/manage",
        name: "ordermanage",
        component: () => import("../views/admin/order/Manage.vue"),
      },
      {
        path: "order/detail",
        name: "orderDetail",
        component: () => import("../views/admin/order/Update.vue"),
      },
      {
        path: "order/refund/manage",
        name: "refundmanage",
        component: () => import("../views/admin/order/refund/Manage.vue"),
      },
      {
        path: "notice/manage",
        name: "noticemanage",
        component: () => import("../views/admin/notice/Manage.vue"),
      },
      {
        path: "notice/register",
        name: "noticeRegister",
        component: () => import("../views/admin/notice/Register.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { x: 0, y: 0 };
      // return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
